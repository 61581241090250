import React from "react"
import PropTypes from "prop-types"
import Check from "../../resources/css/check.svg"

export default function Tick(props) {

    return (

        <p style={{display:"flex"}}>
          <img height="36.44" src={Check} alt="Check" style={{"marginRight":"10px"}}></img>{props.children}
        </p>
        
    )
    
}
Tick.propTypes = {
  children: PropTypes.node.isRequired,
}