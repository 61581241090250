import React from "react"
import PropTypes from "prop-types"

export default function Position(props) {

    return (

        <div className="col-4">
          <div className="position-box">
            <h3>{props.name}</h3>
            <table style={{margin:"0"}}>
              <tr>
                <td><span style={{fontWeight:"800"}}>Stack: </span></td> <td>{props.stack}</td>
              </tr>
              <tr>
                <td><span style={{fontWeight:"800"}}>Level: </span></td> <td>{props.level}</td>
              </tr>
              <tr>
                <td><span style={{fontWeight:"800"}}>Location: </span></td> <td>{props.location}</td>
              </tr>
            </table>
          </div>
        </div>
        
    )
    
}
Position.propTypes = {
  children: PropTypes.node.isRequired,
}