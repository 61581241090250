import React from "react"

export default function SectionDivider(props) {

    const white = props.white;
    if (white) {
        return (
            <div className="row mb40">
              <div className="col-2"><div className="section-divider" style={{"color":"#FFF"}}>{props.name}</div></div>
              <div className="col-10"><hr style={{"borderTop":"1px solid #FFF"}}></hr></div>
            </div>
        )
    }
    return (
        <div className="row mb40">
          <div className="col-2"><div className="section-divider" style={{"color":"#000"}}>{props.name}</div></div>
          <div className="col-10"><hr style={{"borderTop":"1px solid #000"}}></hr></div>
        </div>
    )

    
    
}